.filters-container {
    position: absolute;
    z-index: 90;

    .filters {
        position: relative;
        padding-left: 56px;
        padding-right: 56px;
        background-color: $second-color;
        border: 0 !important;
        border-radius: 0;

        @include mq(width, 'xs', max) {
            padding-right: 0px;
            padding-left: 0px;
        }

        select {
            width: 250px;
            margin: 4px;
            -webkit-appearance: none;
            background-position: calc(100% - 12px);
            background-size: 12px;
            background-repeat: no-repeat;
            border: 0;
            color: white;
            font-size: 14px;
        }

        #seal-filter {
            width: 250px;
            height: 38px;
            display: flex;
            flex-direction: row;
            border: 2px solid $hover-color;
            border-radius: 2px;
            color: $hover-color;
            font-size: 14px;
            cursor: pointer;

            .seal-logo {
                width: 25%;
                border-right: 2px solid $hover-color;

                .active-seal {
                    opacity: 0.3;
                }
            }

            .seal-text {
                width: 75%;
            }
        }

        .active {
            border-color: white !important;
            color: white !important;

            .seal-logo {
                border-color: white !important;
            }
        }
    }
}