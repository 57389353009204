@import '../base/_variables';

%default-separator {
    width: 50px;
    margin: 0px 0px 8px 0px;
}

.light-separator {
    @extend %default-separator;
    border-top: 5px solid $second-color;
}
