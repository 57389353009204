@import '../base/_variables';
@import '../base/mixins';

.btn-info {
    display: inline-block;
    margin-bottom: 4px;
    padding: 8px 14px;
    border: 0;
    border-radius: 3px;
    background-color: $second-color;
    color: white;
    font-weight: bold;
    &:hover {
        text-decoration: none;
        background-color: $hover-color;
    }
}

.btn-reserve {
    position: fixed;
    bottom: 40px;
    right: 0;
    padding: 16px 24px;
    background-color: $second-color;
    color: white;
    z-index: 90;

    &:hover {
        text-decoration: none;
        color: white;
        background-color: $hover-color;
    }
}

.btn-centers {
    position: fixed;
    bottom: 30px;
    right: 60px;
    padding: 8px;
    background-color: $second-color;
    color: white;
    font-size: 14px;
    z-index: 90;

    @include mq(width, 'xs', max) {
        bottom: 0;
        left: 0;
        width: 100%;
        border-radius: 0;
    }

    &:hover {
        text-decoration: none;
        color: white;
        background-color: $hover-color;
    }
}

.btn-see-more {
    display: inline-block;
    text-decoration: none;
    background-color: white;
    border: solid 1px $main-color;
    padding: 20px 40px;
    border-radius: 0;
    color: $main-color;
    &:hover{
        color: white;
        background-color: $main-color;
    }
}

.btn-filter {
    display: inline-block;
    text-decoration: none;
    background-color: white;
    border: 2px solid $main-color;
    border-radius: 4px;
    padding: 8px;
    color: $main-color;
    font-size: 14px;

    &:hover{
        color: white;
        background-color: $main-color;
    }
}

.disable {
    background-color: #CCC;
    cursor: default;

    &:hover{
        background-color: #CCC;
    }
}
