@import '../../../../rota_romanico/src/rota_romanico/scss/base/variables';
@import '../../../../rota_romanico/src/rota_romanico/scss/base/mixins';

$poi-gallery-height: 50%;
$gallery-height: 650px;
$gallery-height-responsive: 400px;

#landing-section-gallery {
    position: relative;
    height: $gallery-height;

    @include mq(width, 'sm', max) {
        height: $gallery-height-responsive;
    }

    .item {
        height: $gallery-height;

        @include mq(width, 'sm', max) {
            height: $gallery-height-responsive;
        }
    }
}
#simple-section {
    position: absolute;
    left: 0;
    height: $gallery-height;

    @include mq(width, 'sm', max) {
        height: $gallery-height-responsive;
    }

    .item {
        height: $gallery-height;

        @include mq(width, 'sm', max) {
            height: $gallery-height-responsive;
        }
    }

    .gallery-wrapper {
        height: 100%;
    }
}
#poi-landing-section {
    position: relative;
    height: $poi-gallery-height;
    .item {
        height: 100%;
    }
}

.full-gallery {
    position: relative;

    .full-caption {
        position: absolute;
        bottom: 10px;
        right: 40px;
        height: 30px;
        width: auto;
        color: white;
        font-weight: 300;
        display: inline-flex;
    }
    .caption-wrapper {
        position: absolute;
        top: 110px;
        right: 0px;
        @include mq(width, 'xs', max) {
            right: 15px !important;
        }
        @include mq(width, 'md', max) {
            right: 60px;
        }
        height: 30px;
        width: auto;
        color: #fff;
        font-weight: 300;
        display: inline-flex;
    }
    .caption {
        padding-left: 15px;
        padding-right: 15px;
        line-height: 30px;
    }
    .gallery-full-screen {
        position: relative;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 4px;
        line-height: 30px;
        background-color: rgba(0, 0, 0, 0.5);
        &:hover {
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.4);
        }
    }
    .infoGallery{
        position: absolute;
        top: auto !important;
        bottom: 40px;
        z-index: 1;
        padding-left: 60px;
        padding-right: 60px;
        color: white;
        font-weight: bold;
        @include mq(width, 'xs', max) {
            padding-left: 15px;
            padding-right: 15px;
        }
        @supports (-webkit-overflow-scrolling: touch) {
            left: 0;
        }

        .breadcrumb{
            li, a{
                color: white !important;
            }
        }

        .gallery-info {
            position: relative;
            bottom: 40px;

            @include mq(width, 'xs', max) {
                h2 {
                    font-size: 25px;
                }
            }
        }

        h1 {
            font-size: 48px;
        }
    }
}

.description {
    color: $main-color;
}

.item {
    position: relative;
}

.slick-list{
    height: 100%;
}

.prevArrow{
    position: absolute;
    z-index: 1;
    color: white;
    top: 50%;
    left: 1rem;
    cursor: pointer;
}

.nextArrow{
    position: absolute;
    right: 1rem;
    z-index: 1;
    color: white;
    top: 50%;
    cursor: pointer;
}
