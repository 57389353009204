@import '../base/_variables';

#cookiesModal {
  display: none;
  background-color: white;
  border: 1px #cccccc solid;
  position: fixed;
  left: 20px;
  bottom: 20px;
  padding: 50px;
  max-width: 536px;
  z-index: 4001;
  .cookies-title {
    font-size: 24px;
    color: $text-color;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .cookies-body {
    font-size: 16px;
    color: $text-color;
    margin-bottom: 24px;
  }
  button.cookies-accept-btn {
    background-color: $text-color;
    color: white;
    border-radius: 0;
    border: 1px $text-color solid;
    padding: 8px 24px;
    margin-bottom: 24px;
    &:hover {
      background-color: white;
      color: $text-color;
      cursor: pointer;
      text-decoration: none;
    }
  }
  .cookies-footer {
    font-size: 12px;
    color: $text-color;
    a {
      text-decoration: underline;
      color: $second-color;
      &:hover {
        text-decoration: none;
        color: $hover-color;
      }
    }
  }
}