.contacts {
    margin: 20px 0px;

    i {
        color: $second-color;
    }

    p {
        margin: 0;
        line-height: 2;
    }

    a {
        color: $text-color;
        text-decoration: none;
    }
}
