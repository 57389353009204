@import 'variables';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');

html, body {
    color: #33332C;
    font-family: $main-font;
    font-size: 16px;
    text-rendering: auto;
    -webkit-font-smoothing: subpixel-antialiased;
}

body {
    overflow-x: hidden;
    margin: 0;
}

h1 {
    font-family: $main-font;
    color: $main-color;
    font-size: 62px;
}

h2 {
    font-size: 40px;
    font-family: $main-font;
    font-weight: bold;
    color: white;
}

h3 {
    font-size: 24px;
    font-family: $main-font;
    text-transform: uppercase;
}

h4 {
    font-size: 24px;
    font-family: $main-font;
}

h5 {
    font-size: 24px;
    font-family: $main-font;
    font-weight: regular;
}
