@import 'base/mixins';
#generic-header{
    .featured-phase{
        padding-left: 4rem;
    }
    i{
        color: $main-color;
    }
}

#generic-header-gallery{
    h1, i{
        color: white;

        @include mq(width, 'sm', max) {
            font-size: 30px;
        }
    }
    
    .rs_skip {
        position: relative;
        z-index: 10;
    }

    .readmore {
        float: right;
        position: absolute;
        z-index: 10;
        top: 71px;
        right: -25px;

        @include mq(width, 'lg', max) {
            right: 0;
        }

        @include mq(width, 'md', max) {
            right: -10px;
        }

        @include mq(width, 'sm', max) {
            top: 90px;
            left: 14px;
        }
    }

    .expand-button {
        text-decoration: underline;
        color: $second-color;
        font-weight: bold;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
            color: $second-color;
        }
    }

    .special-text {
        position: relative;
    }

    .rich-text {
        font-weight: 500;
    }
}

.rs_skip {
    position: relative;
    float: right;
    z-index: 10;

    i {
        margin: 4px 0px 0px 4px;
    }
}
