@import 'variables';

/* flex */
@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex:  $values;
    -webkit-flex:  $values;
    -ms-flex:  $values;
    flex:  $values;
}

@mixin order($val) {
    -webkit-box-ordinal-group: $val;
    -moz-box-ordinal-group: $val;
    -ms-flex-order: $val;
    -webkit-order: $val;
    order: $val;
}

/* media queries*/
@mixin mq($val, $width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-#{$val}: $width) {
            @content;
        }
    }
}

@mixin mq-between($val, $lower, $upper) {
    @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
      $lower-breakpoint: map-get($breakpoints, $lower);
      $upper-breakpoint: map-get($breakpoints, $upper);
      @media (min-#{$val}: $lower-breakpoint) and (max-#{$val}: ($upper-breakpoint - 1)) {
        @content;
      }
    } @else {
      @if (map-has-key($breakpoints, $lower) == false) {
        @warn 'Your lower breakpoint was invalid: #{$lower}.';
      }
      @if (map-has-key($breakpoints, $upper) == false) {
        @warn 'Your upper breakpoint was invalid: #{$upper}.';
      }
    }
  }

/* position*/
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

/* overlay */
@mixin default-overlay($color, $opacity: .5, $h: 100%) {
        position: absolute;
        width: 100%;
        height: $h;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: $opacity;
        background-color: $color;
}

/* font styles */
@mixin font-source-sans($size: false, $colour: false, $weight: false) {
    @if $size { font-size: $size; }
    @if $colour { color: $colour; }
    @if $weight { font-weight: $weight; }
}

/* block of information (activities and programs) */
@mixin block-info($color) {
    background-color: $color;
    color: #fff;
    padding: 50px;
}

/* text maximum number of lines */
@mixin text-max-lines($lines) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
}