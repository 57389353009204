.half-gallery {
    position: relative;

    .slider {
        .gallery-img {
            height: calc(100vh - 166px);
            @include mq(width, 'lg', max) {
                height: 50vh;
            }
            background-size: cover !important;
            background-position: center !important;
        }
    }

    .caption-wrapper {
        z-index: 10;
        position: absolute;
        float: right;
        right: 40px;
        bottom: 20px;
        height: 30px;
        width: auto;
        color: #fff;
        font-weight: 300;
        display: inline-flex;
    }
    .caption {
        padding-left: 15px;
        padding-right: 15px;
        line-height: 30px;
    }
    .gallery-full-screen {
        position: relative;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 4px;
        line-height: 30px;
        background-color: rgba(0, 0, 0, 0.5);
        &:hover {
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.4);
        }
    }
}
