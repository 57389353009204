.header {
    padding-top: 20px;
    padding-left: 60px;
    padding-right: 60px;
    @include mq(width, 'xs', max){
        padding-left: 15px;
        padding-right: 15px;
    }

    h2 {
        margin: 0;
        color: $main-color;
        font-weight: bold;
        word-wrap: break-word;
    }

    .breadcrumb {
        padding-top: 0;
    }

    p {
        color: $main-color;
    }

    .page-title {
        max-width: 285px;
    }

    .filter-btns {
        i {
            color: $main-color;
        }

        a {
            cursor: pointer;

            &:hover {
                i {
                    color: $second-color;
                }
            }
        }

        .search-container {
            display: none;
            margin-right: 10px;

            .search-bar {
                max-width: 300px;
            }

            .search-clear {
                position: absolute;
                right: 40px;
                bottom: 8px;
                display: none;
                opacity: 0.4;
                height: 14px;
                color: $main-color;
                font-size: 14px;
                cursor: pointer;

                &:hover {
                    opacity: 0.6;
                }
            }
        }
    }

    .dropdown {
        #dropdown {
            padding-right: 0;
        }

        .dropdown-filter {
            border: 2px solid $main-color;
            color: $main-color;
            font-size: 14px;
            text-transform: none;

            &:hover {
                color: $main-color !important;
            }
        }

        .days-button {
            text-transform: none;
        }

        .dropdown-toggle{
            color: $main-color;
            font-weight: bold;
            font-size: 22px;
        }
        .dropdown-item:active{
            background-color: transparent;
        }
    }

    .active-icon {
        i {
            color: $second-color;
        }
    }

    .special-text {
        color: #7e7d6c;
        font-size: 18px;
        .rs_skip {
            position: relative;
            float: right;
            z-index: 10;

            i {
                margin: 4px 0px 0px 4px;
            }
        }
        .expand-button {
            text-decoration: underline;
            color: $second-color;
            font-weight: bold;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
                color: $second-color;
            }
        }
    }
}