#menu{
    position: fixed;
    z-index: 100;
    border: 0;
}

#simple-menu{
    position: relative;
    z-index: 100;
    border: 0;
}

.menu-container{
    .navbar-brand{
        img{
            height: 60px;
        }
    }

    .show {
        @include mq(width, 'lg', max) {
            height: calc(100vh - 86px);
            display: flex;
            flex-direction: column;
            justify-content: center;

            .navbar-nav {
                margin-right: 0 !important;
            }
        }
    }

    .favorites-num {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -4px;
        right: -8px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #00a8cb;
        color: white;
        font-size: 10px;
    }

    .collapse {
        align-self: flex-end;

        a:hover {
            color: $second-color !important;
            text-decoration: none;
        }

        .nav-responsive{
            text-align: center;
            li{
                padding-bottom: 4px;
            }
        }

        a.dropdown-item:focus {
            background-color: #f8f8f8;
        }

        .dropdown-menu.show {
            left: auto;
        }
    }

    .collapsing {
        text-align: center;

        ul {
            li {
                padding-bottom: 4px;
            }
        }
    }

    .dropdown {
        .btn-secondary {
            background-color: transparent !important;
            border: none !important;
            box-shadow: none !important;
        }
    }

    #menu-content{
        span, a{
            color:white;
            font-weight: bold;
        }

        .dropdown{
            height: auto !important;

            .dropdown-menu {
                height: auto !important;
            }
        }
    }
    #menu-simple{
        span, a{
            color:$main-color;
            font-weight: bold;
            white-space: nowrap;
        }
        .dropdown{
            height: auto !important;

            .dropdown-menu {
                height: auto !important;
            }

            button{
                color:$main-color;
            }
        }
    }
}

.menu-icons {
    max-height: 44px;

    .nav-item {
        @include mq(width, 'lg', max) {
            margin-left: 10px;
        }
    }

    .favorites-num {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: -32px;
        left: 18px;
        @include mq(width, 'lg', max) {
            left: 10px;
        }
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: #00a8cb;
        color: white;
        font-size: 10px;
        cursor: pointer;
    }
}

.dropdown {
    .dropdown-toggle {
        background: none;
        border: 0;
        box-shadow: none;
        font-weight: bold;

        &:hover {
            color: $second-color !important;
        }

        &::after {
            display: none;
        }
    }

    .dropdown-menu {
        .dropdown-item {
            color: $main-color !important;
        }
    }
}

.langs {
    .dropdown-toggle {
        text-transform: uppercase;
    }

    .dropdown-menu {
        min-width: auto;

        .dropdown-item {
            text-transform: uppercase;
        }
    }
}

.navbar-collapse.collapse {
    transition: height 0.5s;
}
.navbar-collapse.collapsing {
    height: 0 !important;
}
.navbar-collapse.collapse.in {
    max-height: none;
    height: 100vh;
}

.affix {
    background-color: $main-color !important;
    top: 0px;
    position: fixed;
    z-index: 100;
    border: 0px;
    width: 100%;

    .navbar {
        span, a{
            color:white !important;
            font-weight: bold;
        }

        .dropdown{
            button{
                color: white !important;

                &:hover {
                    color: $second-color !important;
                }
            }
        }

        .navbar-toggler {
            position: relative;

            .favorites-num {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: -4px;
                right: -8px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: #00a8cb;
                color: white;
                font-size: 10px;
            }

            i {
                color: white !important;
            }
        }
    }
}

.dropdown:hover .dropdown-menu{
    @include mq(width, 'lg', min) {
        display: block;
        margin-top: 0;
        left: auto;
    }
}

.navbar {
    background-color: transparent;
    padding-left: 60px;
    padding-right: 60px;
    @include mq(width, 'xs', max){
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .drawer-dark {
        position: relative;

        i {
            color: $main-color !important;
        }
    }

    .navbar-toggler {
        position: relative;
        padding: 0;
        border: none;

        i {
            margin: 2px 0px;
            color: white;
        }
    }
}

.search-form {
    position: absolute;
    top: 80px;
    right: 60px;
    width: 210px;

    @include mq(width, 'lg', max) {
        position: initial;
    }

    .form-group {
        .form-control {
            border-radius: 0px;
        }
    }
}

.hidden {
    display: none;
}

.active-icon {
    color: $second-color;
}

.hovered-subcategory {
    color: $hover-color !important;
}