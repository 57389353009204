#footer {
    padding-top: 50px;
    padding-bottom: 10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    p {
        margin: 0;
        padding-bottom: 8px;
        color: white;
        font-size: 14px;
    }

    .logo {
        margin-bottom: 50px;

        img {
            height: 65px;
        }
    }

    .social {
        h4 {
            margin-bottom: 10px;
            color: white;
            font-size: 18px;
            font-weight: 300;

            strong {
                font-weight: 700;
            }
        }

        a {
            color: #474747;
            text-decoration: none;

            &:hover {
                color: #AAA491;

                .button {
                    background-color: #474747;
                }
            }

            .button {
                width: 34px;
                height: 34px;
                background-color: #AAA491;
                border-radius: 50%;
            }
        }
    }

    .menu {
        h4 {
            margin-bottom: 8px;
            color: white;
            font-size: 16px;
            font-weight: bold;
        }

        ul {
            padding: 0;
            list-style-type: none;

            li {
                margin-bottom: 4px;

                a {
                    color: white;
                    font-size: 16px;
                    font-weight: 300;
                }
            }
        }
    }

    .apps {
        h4 {
            margin-bottom: 8px;
            color: white;
            font-size: 14px;
            font-weight: 400;
        }

        a:hover {
            img {
                opacity: 0.8;
            }
        }
    }

    .newsletter {
        margin-top: 30px;

        .newsletter-input {
            position: relative;
            width: 225px;
            padding: 10px;
            padding-right: 30px;
            background: transparent;
            border: 1px solid white;
            color: white;

            &::placeholder {
                color: white;
            }
        }

        .newsletter-load {
            position: absolute;
            left: 200px;
            color: white;
            font-size: 18px;
        }

        .newsletter-btn {
            padding: 10px;
            background-color: white;
            border: 1px solid white;
            border-left: 0;
            color: #7F7966;
            font-size: 10px;
            font-weight: bold;

            &:hover {
                color: white;
                background-color: #33332c;
            }
        }

        .newsletter-response {
            position: absolute;
        }
    }

    .partnerships {
        margin-top: 35px;
        margin-bottom: 35px;

        .col-2 {
            padding-left: 0;
        }

        a {
            display: inline-block;
            margin-right: 25px;
        }
    }

    .copyright {
        padding: 0;
        color: white;
        font-size: 12px;
        font-weight: 300;

        a {
            color: inherit;
        }
    }
}
