@import 'mixins';

.highlight-background{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
}

.vhcenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.vcenter {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.hcenter {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.dark-overlay {
    background: black;
    bottom: 0;
    left: 0;
    opacity: 0.3;
    position: absolute;
    right: 0;
    top: 0;
}

.gallery-wrapper {
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
}

.img-wrapper{
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 0;
    left: 0;
}

.item-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .text-wrapper {
        position: absolute;
        width: 100%;
        bottom: 0;
        color: white;
        height: 20%;
        .text-content {
            text-align: left;
            font-weight: bold;
            color: white;
            padding: 0 25px;
            position: absolute;
            bottom: 30%;
            width: 100%;
            .title {
                line-height: 1.35;
                font-size: 26px;
            }
            .subtitle{
                font-size: 16px;
            }
        }

        .half-text {
            @include mq(width, 'lg', min) {
                max-width: 50%;
            }
        }
    }
}

.ar-dummy{
    &.ar33{padding-bottom: 33.333%;}
    &.ar40{padding-bottom: 40%;}
    &.ar45{padding-bottom: 45%;}
    &.ar50{padding-bottom: 50%;}
    &.ar60{padding-bottom: 60%;}
    &.ar66{padding-bottom: 66.667%;}
    &.ar70{padding-bottom: 70%;}
    &.ar75{padding-bottom: 75%;}
    &.ar80{padding-bottom: 80%;}
    &.ar90{padding-bottom: 90%;}
    &.ar100{padding-bottom: 100%;}
    &.ar120{padding-bottom: 120%;}
    &.ar130{padding-bottom: 130%;}
    &.ar160{padding-bottom: 160%;}
}

.fa-volume-up{
    color: $main-color;
}

.rich-text {
    h2, h3, h4, h5 {
        color: $main-color;
    }

    a {
        color: $second-color;
        font-weight: bold;

        &:hover {
            text-decoration: none;
        }
    }

    .responsive-object {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 25px;
        height: 0;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.no-results {
    max-width: 500px;
    text-align: center;
    color: $main-color;
    font-weight: 700;
}

#map-container {
    .ol-control {
        background-color: transparent;
        border-radius: 0px;
        padding: 0px;

        .ol-center {
            margin: 10px 4px;

            img {
                height: 14px;
            }
        }

        button {
            margin: 0px 4px;
            border-radius: 1px;
            background-color: white;
            color: #646464;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
            cursor: pointer;

            &:hover {
                background-color: #f8f8f8;
            }
        }
    }
}

button:focus {
    outline: none;
}

.fb-comments iframe {
    width: 100% !important;
}

.read-more-button {
    display: inline-block !important;
    width: auto !important;
    text-decoration: none !important;
    font-weight: 700;
    color: $second-color;

    &:hover {
        color: $hover-color;
    }
}