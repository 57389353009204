#breadcrumbs {
    padding: 0;

    .breadcrumb {
        background-color: transparent !important;
        padding-left: 0 !important;
        padding-bottom: 0 !important;
        padding-right: 0 !important;
        margin: 0 !important;

        li, a {
            color: $main-color;
            font-size: 14px;
            font-weight: 300;
        }

        a:hover {
            color: $main-color;
            text-decoration: underline;
        }
    }
}
